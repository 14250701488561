body {
	margin: 0;
	background-color: transparent;
	color: #fff;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 24px;
	overscroll-behavior: none;

}
.pc-only{
	display: block;
}
.sp-only{
	display:none;
}
a {
	color: #523d6f;
	text-decoration: none;
}

h3 {
	font-size: 48px;
	line-height: 60px;
	margin-block-start: 0;
	margin-block-end: 0;
}

a:hover {
	text-decoration: underline;
}

button {
	cursor: pointer;
	text-transform: uppercase;
}

#info {
	position: absolute;
	top: 0px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 1;
	/* TODO Solve this in HTML */
}

a,
button,
input,
select {
	pointer-events: auto;
}

body {
	user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	overflow: hidden;
}

.dg.ac {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 2 !important;
	/* TODO Solve this in HTML */
}

#overlay {
	position: absolute;
	font-size: 16px;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: transparent;
}

#overlay button {
	background: transparent;
	border: 0;
	border: 1px solid rgb(255, 255, 255);
	border-radius: 4px;
	color: #ffffff;
	padding: 12px 18px;
	text-transform: uppercase;
	cursor: pointer;
}


#notSupported {
	width: 50%;
	margin: auto;
	background-color: #f00;
	margin-top: 20px;
	padding: 10px;
}

.controller.fal {
	padding-top: 35px;
	
}
.fal{
	line-height: 2!important;
}
.hover {
	color: rgba(0, 0, 0, 1)
}


.instruction-btn{
	margin: 16px;
	padding: 0 16px;
	text-align: center;
	background: rgba(202, 175, 209, 0.2);
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 2.0px );
	-webkit-backdrop-filter: blur( 6.0px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
	text-align:justify;
}
.instruction-btn .fal{
	font-size: 18px;
	line-height: 0;
}
.point {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(255, 255, 255, 1);
	transform: scale(0, 0);
	transition: all 2s ease-in;
	overflow-y: scroll;

}
.top__instructions{
	position: absolute;
	top: 12vh;
	width: 50%;
    right: 0;
    z-index: 100;
	left: 0;
	color:#666666;
    margin: auto;
    text-align: center;
	bottom: 0;
	height: 410px;
	background: rgba(202, 175, 209, 0.2);
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 2.0px );
	-webkit-backdrop-filter: blur( 6.0px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.top__instructions .sub-header{
	background: transparent;
}
.top__instructions .keys-instruction{
	justify-content: center;
}
.top__instructions .keys-instruction .key-instructions{
	color: #666666;
}
.top__instructions .keys-instruction .key-instructions .key{
	border:1px solid  #666666;
	font-size: 16px;
}
.top__instructions .keys-instruction .key-instructions .keyMiddle{
	border: white;
}
.top__instructions .fal{
	font-size: 24px;
	line-height:0!important;
}
.top__instructions .audioBox p{
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 20px;
}
.audioBox{
	top: 72%;
	position: absolute!important;
	z-index: 100!important;
	text-align: center;
	font-size: 24px;
	width: 100%;
	
}
.audioBox-inner{
	width: 30%;
	position: relative;
	margin:16px auto;
	
}
.audioCheck-pc{
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 16px;
	margin: 8px auto;
	z-index:100;	
}
.audioCheck-pc button{
	background: none;
	border:none;
	font-size: 18px;
	color: #c4c4c4;
}


.controller {
	display: none;
}
.close{
	display: none;
} 

@media screen and (max-width:480px) {
	.pc-only{
		display: none;
	}
	.sp-only{
		display:block;
	}
	.audioCheck-sp{
		z-index: 1;
		position: absolute;
		top: 12px;
		left: 16px;
	}
	#wave{
		width: 40px !important;
	}
	.audioWaveSp{
		display: flex;
		gap: 16px;
	}
	.audioBtn{
		cursor: pointer;
		text-transform: uppercase;
		background: none;
		box-shadow: none;
		border: none;
		color: white;
		font-size: 18px;
		padding:0;
	}
	
	.controls p{
		margin-block-start:0;
		margin-block-end:0;
	}
	#controller-forward {
		position: fixed;
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: center;
		align-content: center;
		bottom: 180px;
		font-size: 40px;
		color: rgba(255, 255, 255, .5);
		z-index: 1;
	}

	#controller-right {
		position: fixed;
		width: 120px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-content: center;
		bottom: 92px;
		right: 67.5px;
		font-size: 40px;
		color: rgba(255, 255, 255, .5);
		z-index: 1;
	}

	#controller-back {
		position: fixed;
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: center;
		align-content: center;
		bottom: 2px;
		font-size: 40px;
		color: rgba(255, 255, 255, .5);
		z-index: 1;
	}

	#controller-left {
		width: 120px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-content: center;
		position: fixed;
		bottom: 92px;
		left: 67.5px;
		font-size: 40px;
		color: rgba(255, 255, 255, .5);
		z-index: 1;

	}

	h3 {
		font-size: 40px;
		line-height: 40px;
	}

	.controller {
		display: block;

	}

	.close {
		display: block;
		position: absolute;
		left: 8px;
		top: 8px;
		font-size: 22px;
		color: rgba(0, 0, 0, .3);
		font-weight: 900;
		z-index: 10;
		background: rgba(255, 255, 255, .2);
		border-radius: 8px;
		padding: 8px 12px;
		box-shadow: none;
	}

	.point__container {
		display: block !important;
		position: absolute !important;
		top: 64px;
		left: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		margin-bottom: 40px!important;
		overflow-y: scroll;
		margin: auto !important;
	}

	.inner {
		width: 100% !important;
		margin: 0 auto !important;
	}
	.inner-para{
		width: 92%!important;
		margin: 16px auto!important;
	}
	.inner img {
		width: 375px!important;
		height: 375px;
		margin: 0 auto;
	}
	.inner video {
		width: 375px!important;
		height: auto;
		margin: 0 auto;
	}

	.inner .inner-para {
		width: 92% !important;
		padding: 0 12px;
		margin: 0 auto;
	}

	.inner .buy {
		margin: 10px auto;
		padding: 8px 24px;
		font-size: 18px;
		background: rgba(0, 0, 0, .6);
		border-radius: 20px;
		box-shadow: 2px 2px 12px rgba(0, 0, 0, .3);
		justify-content: center;
		border: none;
		color: white;
		transition: all .5s ease-in-out;
	}

	.instructions__container h3 {
		font-size: 7vmax;
		line-height: 1.2;
		color: #333333;
	}
	.page-title{
		width: 100%;
		text-align: center!important;
	}
	.page-container{
		height: 1000px;
	}
	.page-wrapper{
		display:block!important;
		width: 100%!important;
		padding: 16px 0 !important;
		overflow-y: scroll!important;
	}
	.main-container{
		display: block!important;
		margin-top: 40px!important;
	}
	.main-inner{
		width:92%!important;
		margin: 24px auto;
	}
	.main-inner img{
		width: 100%!important;
	}
    
	aside{
		padding-left: 24px;
	}
	main{
		width: 92%!important;
		margin: 0 auto!important;
	}
	.btn-store{
		width: 309px;
	}
	.btn-gallery{
		position: absolute;
		top:32px;
		right:24px!important;
		font-size: 26px!important;
		color: white;
		z-index:1!important;
	}
	.lockInstruction{
		display: none;
	}
	.top__instructions{
		display: none;
	}
	.audioWave{
		display: none;
	}
	.btn-back{
		position: relative!important; 
		left:8px!important;
		top:8px;
		background: none!important;
		color: #333333;
		font-size: 12px !important;
		border:none;
		padding-left:8px;
	}

}

.point:hover .text {
	opacity: 1;

}

.point.visible {
	animation: rotate 1s 1 linear forwards;

}

@keyframes rotate {
	0% {
		transform: scale(0, 0);
		opacity: 0;
	}

	100% {
		transform: scale(1, 1);
		opacity: 1;
	}
}

.point__container {
	display: flex;
	position: relative;
	margin: auto;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.inner {
	color: #333333;
	display: block;
	width: 50%;
}
.inner img{
	width: 80%;
}
.inner video{
	width: 60%;
	display: flex;
	justify-self: center;
	margin: 0 auto;
}
.inner-para{
 padding: 0 24px 0 0;
}
.inner span {
	color: rgba(0, 0, 0, .4);
}

img {
	margin: 0 auto;
	text-align: center;
	display: block;
	object-fit: 100%;
}

.inner .buy {
	margin: 20px auto;
	padding: 8px 24px;
	background: rgba(0, 0, 0, .6);
	border-radius: 20px;
	box-shadow: 2px 2px 12px rgba(0, 0, 0, .3);
	justify-content: center;
	border: none;
	color: white;
	transition: all .5s ease-in-out;
}

.buy:hover {
	text-shadow:
		0 0 10px rgba(255, 255, 255, 1),
		0 0 50px rgba(255, 255, 255, .8),
		0 0 75px rgba(255, 255, 255, .6),
		0 0 76px rgba(255, 255, 255, .4),
		0 0 77px rgba(255, 255, 255, .5),
		0 0 78px rgba(255, 255, 255, .4),
		0 0 79px rgba(255, 255, 255, .3),
		0 0 80px rgba(255, 255, 255, .2),
		0 0 85px rgba(255, 255, 255, .1);
}

.instructions__container {
	margin: 2em auto;
	width: 90%;
	text-align: justify;
}

.instructions__container h3 {
	font-size: 7vmax;
	line-height: 1.2;
	color: #333333;
}

.instructions__container p {
	font-size: 18px;
	color: #333333;
}

#instructionsPara {
	color: #333333;
	font-size: 28px;
	font-weight: bold;
}

.welcome {
	font-size: 2.4vmax;
	color: rgba(0, 0, 0, .6);
}

.sub-header {
	color: #666666;
	background: #333333;
	padding: 0px 24px;
	margin: 24px 0;
	font-size: 24px;
}

.keys-instruction {
	display: flex;
	align-items: center;
}

.key {
	padding: 4px;
	margin: 8px auto;
	width: 40px;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, .6);
}

.fas {
	line-height: 0;
}

.key-instructions {
	color: #333333;
	font-size: 30px;
	width: 200px;
	align-items: center;
}

.keyMiddle {
	display: flex;
}
.page-container{
	width: 95%;
	margin: 16px auto 40px auto;
	background: white;
	text-align: center;
}
.page-title{
	color:#333333;
	font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	font-size: 18px;
	width: 100%;
	text-align: center;
	padding-top: 12px;
}
.page-wrapper{
	width: 1200px;
	padding: 24px 24px 64px 24px;
	margin:0px auto;
	display: flex;
	background:white
}
.page-wrapper h3{
	color: #333333;
}
aside{
	width: 35%;
	margin-top: 40px;
}

main{
	width: 55%;
	margin-top: 40px;
}
.main-container{
	display: flex;
	justify-content:space-between;
	margin-top:120px;
}
.main-container img{
	width: 300px;
}
.main-inner{
	width: 300px;
	text-align: justify;
	color:#333333;
}
.title{
	margin:16px auto;
	font-size: 20px;
	font-weight: bold;
}
.artby{
	margin: 16px auto;
	font-size: 16px;
	color:rgba(0,0,0,.6);
}
.btn-store{
	width: 300px;
	margin: 24px auto;
	font-size: 18px;
	padding:8px 24px;
	border:none;
	background:#000000;
	color: white;
}
.btn-back{
	position: absolute;
	left:24px;
	top:8px;
	color: #333333;
	font-size: 16px;
	border:none;
	padding-left:8px;
}
.btn-gallery{
	position: absolute;
	top:32px;
	right:46px;
	font-size: 32px;
	color: white;
	transition: color .6s ease;
	z-index:10;
}
.btn-gallery:hover{
	color:#c4c4c4;
}
.audioWave{
	position: absolute;
	bottom:24px;
	left:24px;
	z-index:1;
}
.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
#wave {
	height: 70px;
	width: 70px;
	fill: #fff;
}
#Line_1 {
	animation: pulse 1s infinite;
	animation-delay: 0.15s;
}
#Line_2 {
	animation: pulse 1s infinite;
	animation-delay: 0.3s;
}
#Line_3 {
	animation: pulse 1s infinite;
	animation-delay: 0.45s;
}
#Line_4 {
	animation: pulse 1s infinite;
	animation-delay: 0.6s;
}
#Line_5 {
	animation: pulse 1s infinite;
	animation-delay: 0.75s;
}
#Line_6 {
	animation: pulse 1s infinite;
	animation-delay: 0.9s;
}
#Line_7 {
	animation: pulse 1s infinite;
	animation-delay: 1.05s;
}
#Line_8 {
	animation: pulse 1s infinite;
	animation-delay: 1.2s;
}
#Line_9 {
	animation: pulse 1s infinite;
	animation-delay: 1.35s;
}
@keyframes pulse {
	0% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
   }
	50% {
		transform: scaleY(0.7);
		transform-origin: 50% 50%;
   }
	100% {
		transform: scaleY(1);
		transform-origin: 50% 50%;
   }
}
.lockInstruction{
	position: absolute;
	top:0;
	left:16px;
	font-size: 14px;
	color: white;
}